import { useContext, useState, useCallback } from 'react'
import axios from 'axios'

import BaseApiUrlContext from '../../contexts/BaseUrlApiContext'

export const useOnSubmitHandler = (action) => {
  const [isSent, setIsSent] = useState(false)
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const baseApiUrl = useContext(BaseApiUrlContext)

  const onSubmit = useCallback(
    async (data) => {
      const formData = new FormData()

      formData.set('action', action)
      Object.entries(data).forEach(([key, value]) => {
        formData.set(key, value)
      })
      setIsLoading(true)

      try {
        const response = await axios({
          method: 'post',
          url: baseApiUrl,
          data: formData,
        })

        setIsSent(true)
        setMessage(response.data.data.response_clarification)
      } catch (ex) {
        console.error(`Error while sending action "${action}":`, ex)
      }

      setIsLoading(false)
    },
    [action, setIsSent, setIsLoading, baseApiUrl],
  )

  return {
    onSubmit,
    isSent,
    isLoading,
    message,
  }
}
