import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledSiteMapWrapper,
  StyledSiteMapSections,
  StyledSiteMapSectionsItem,
  StyledSitemapItem,
  StyledSitemapItemHead,
  StyledSitemapItemBody,
  StyledSitemapItemBodyList,
  StyledSitemapItemBodyListElement,
  StyledSiteMapLink,
} from './styles.js'
import { Heading5 } from '../Typography/Typography'

const SiteMap = ({ pages, models, locations, categories }) => {
  const categoriesList = [pages, models, locations, categories]

  return (
    <StyledSiteMapWrapper bg="darkGrey">
      <StyledSiteMapSections>
        {categoriesList.map(({ title, list }) => (
          <StyledSiteMapSectionsItem key={title} className="uiAnimBottom">
            <StyledSitemapItem>
              <StyledSitemapItemHead>
                <Heading5 color="yellow">{title}</Heading5>
              </StyledSitemapItemHead>
              <StyledSitemapItemBody>
                <StyledSitemapItemBodyList>
                  {list.map(({ id, name, slug }) => (
                    <StyledSitemapItemBodyListElement key={id}>
                      <StyledSiteMapLink
                        to={slug}
                        dangerouslySetInnerHTML={{
                          __html: name,
                        }}
                      />
                    </StyledSitemapItemBodyListElement>
                  ))}
                </StyledSitemapItemBodyList>
              </StyledSitemapItemBody>
            </StyledSitemapItem>
          </StyledSiteMapSectionsItem>
        ))}
      </StyledSiteMapSections>
    </StyledSiteMapWrapper>
  )
}

export default SiteMap

SiteMap.propTypes = {
  pages: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
  }),
  models: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
  }),
  locations: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
  }),
  categories: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
  }),
}
