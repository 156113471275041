import styled from 'styled-components'
import Link from 'gatsby-link'

import Container from '../Container'
import media from '../../common/MediaQueries'
import { transition } from '../../common/mixins'

const StyledSiteMapWrapper = styled(Container)`
  position: relative;
  width: calc(100% + 1.875rem * 2);
  padding: 1.875rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;

  @media ${media.phone} {
    width: 100%;
    padding: 1.5rem 3.5rem 0 3.5rem;
    margin-left: auto;
    margin-right: auto;

    &::before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4rem;
      content: '';
      display: block;
      background: inherit;
      width: 100%;
      height: 4rem;
    }
  }
`

const StyledSiteMapSections = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSiteMapSectionsItem = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 3.75rem;
  }
`

const StyledSitemapItem = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSitemapItemHead = styled.div`
  width: 100%;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tundora};
`

const StyledSitemapItemBody = styled.div`
  padding-top: 1.75rem;

  @media ${media.phone} {
    padding-top: 1.75rem;
  }
`

const StyledSitemapItemBodyList = styled.ul`
  column-count: 2;
  column-gap: 1.25rem;

  @media ${media.phone} {
    display: flex;
    flex-wrap: wrap;
    column-count: auto;
  }
`

const StyledSitemapItemBodyListElement = styled.li`
  margin-right: 0.5rem;

  @media ${media.phone} {
    margin-right: 1.5rem;
    margin-bottom: 0.75rem;
  }
`

const StyledSiteMapLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  line-height: 2.25;
  ${transition}

  &:hover {
    opacity: 0.7;
  }
`

export {
  StyledSiteMapWrapper,
  StyledSiteMapSections,
  StyledSiteMapSectionsItem,
  StyledSitemapItem,
  StyledSitemapItemHead,
  StyledSitemapItemBody,
  StyledSitemapItemBodyList,
  StyledSitemapItemBodyListElement,
  StyledSiteMapLink,
}
