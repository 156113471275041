import React, { useMemo } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Inner from '../components/Inner'
import CallMe from '../components/CallMe/CallMe'
import Newsletter from '../components/Forms/Newsletter/Newsletter'
import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import Banners from '../components/Banners/Banners'
import SiteMap from '../components/SiteMap/SiteMap'
import { Heading2 } from '../components/Typography/Typography'
import { TRUNCATED_HERO_TYPES } from '../constants/truncatedHeroTypes'
import { BG_TYPES } from '../constants/bgTypes'
import { URL_PATHS } from '../constants/urlPaths'
import {
  fillWithValueLinks,
  bgTheme,
  newsletterSpacings,
  sortAlphabeticallyBy,
} from '../common/utils'
import mapBannersToList from '../common/queries/mapBannersToList'

const SiteMapPage = ({ data }) => {
  const { edges: pagesEdges } = data.wp.pages
  const { edges: citiesEdges } = data.wp.cities
  const { edges: modelsEdges } = data.wp.models
  const { edges: categoriesEdges } = data.wp.categories
  const seoData = data.wp.page?.seo

  const acf = data.wp.page?.sitemapAcf

  const newsletterBg = bgTheme(acf.newsletterContainerBackground)
  const newsletterSpacing = newsletterSpacings(acf.newsletterBoxMargins)

  const pages = {
    title: 'Pages',
    list: fillWithValueLinks(pagesEdges)
      .sort(sortAlphabeticallyBy('name'))
      .filter(
        ({ slug }) => slug !== '/vip-welcome' && slug !== '/vip-dashboard',
      ),
    // .map((item) => item.slug === '/vip-login' ? { ...item, slug: URL_PATHS.VIP_LOGIN } : item),
  }

  const models = {
    title: 'Models',
    list: fillWithValueLinks(modelsEdges, `${URL_PATHS.MODELS}`).sort(
      sortAlphabeticallyBy('name'),
    ),
  }

  const locations = {
    title: 'Locations',
    list: fillWithValueLinks(citiesEdges, `${URL_PATHS.MODELS}`).sort(
      sortAlphabeticallyBy('name'),
    ),
  }

  const mappedCategoriesEdges = useMemo(
    () =>
      categoriesEdges.filter(
        (category) => category.node.slug !== 'uncategorized',
      ),
    [categoriesEdges],
  )

  const categories = {
    title: 'Categories',
    list: fillWithValueLinks(
      mappedCategoriesEdges,
      `${URL_PATHS.CATEGORY}`,
    ).sort(sortAlphabeticallyBy('name')),
  }

  const banners = mapBannersToList(acf)

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />
      <Container bg={BG_TYPES.black}>
        <TruncatedHero
          header={
            <Heading2
              as="h1"
              color="white"
              textAlign="center"
              dangerouslySetInnerHTML={{
                __html: acf.heroFields.title,
              }}
            />
          }
          heroImages={acf.heroFields.bgImg.imageFile.childImageSharp.fluid}
          positionImg="50% 0%"
          heroType={TRUNCATED_HERO_TYPES.withShortHeroOnMobile}
          gradient="top"
          centered
        />
        <Container
          mt={['0', '-4rem', '-17rem']}
          mb={['', '6.25rem']}
          position="relative"
          zIndex={2}
        >
          <Inner>
            <SiteMap
              pages={pages}
              models={models}
              locations={locations}
              categories={categories}
            />
          </Inner>
        </Container>
      </Container>

      <Container
        pt={newsletterSpacing}
        pb={newsletterSpacing}
        bg={newsletterBg}
      >
        <Inner pl={['0', '1.875rem']} pr={['0', '1.875rem']}>
          <Newsletter />
        </Inner>
      </Container>

      <Container mb={['1rem', '3.75rem']}>
        <Inner>
          <Banners banners={banners} />
        </Inner>
      </Container>

      <CallMe info={acf.ctaBarText} phone={acf.ctaBarPhone} />
    </Layout>
  )
}

export default SiteMapPage

export const query = graphql`
  query {
    wp {
      pages(first: 100) {
        edges {
          node {
            id
            title
            slug
          }
        }
      }
      cities(first: 100) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
      models(first: 50) {
        edges {
          node {
            id
            title
            slug
            acf {
              ...modelDetailsFragment
              comingSoon
            }
          }
        }
      }
      categories(first: 100) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
      page(id: "sitemap", idType: URI) {
        seo {
          ...seoFragment
        }
        sitemapAcf {
          underNewsletterBoxLeft {
            title
            content
            bgImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 570) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          underNewsletterBoxRight {
            title
            content
            bgImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 570) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          heroFields {
            title
            bgImg {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ctaBarPhone
          ctaBarText
          newsletterBoxMargins
          newsletterContainerBackground
        }
      }
    }
  }
`
